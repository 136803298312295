
import moment from 'moment';

export const ROLES_NAME = {
  PRODUCT_KIOSK: 'Product: Kiosk',
  PRODUCT_BAS: 'Product: BAS',
  PRODUCT_KINGKEY: 'Product: Kingkey',
  PRODUCT_TWCS: 'Product: TWCS',
  PRODUCT_SME: 'Product: SME',
  PRODUCT_NXMAP: 'Product: NxMap',
  PRODUCT_HUBS: 'Product: Hubs',
  NOTIFY_NEW_MEMBER_AUDITOR: 'Notify: New Member Auditor',
  NOTIFY_MEMBER_EXPIRY: 'Notify: Member Expiry Notice',
  NOTIFY_COOLING_PERIOD: 'Notify: Cooling Period Receiver',
};

export const namedMapper: { [key: string]: string; }[] = [
  { fundHub: 'Fund Hub' },
  { portfolioHub: 'Portfolio Hub' },
  { companyHub: 'Company Hub' },
  { climateHub: 'Climate Hub' },
  { impactHub: 'Impact Hub' },
  { kioskHomepage: 'Kiosk Homepage' },
  { kioskFundhub: 'Kiosk Fund Hub' },
  { kioskPortfoliohub: 'Kiosk Portfolio Hub' },
  { fundSearch: 'Fund: Search' },
  { fundHubScreener: 'Fund Hub Screener' },
  { companyHubScreener: 'Company Hub Screener' },
  { productKiosk: ROLES_NAME.PRODUCT_KIOSK },
  { notifyNewMemberAuditor: ROLES_NAME.NOTIFY_NEW_MEMBER_AUDITOR },
  { notifyMemberExpiry: ROLES_NAME.NOTIFY_MEMBER_EXPIRY },
  { notifyCoolingPeriod: ROLES_NAME.NOTIFY_COOLING_PERIOD },
  { portfolioHubPlus: 'Portfolio Hub Plus' },

  { stewardshipHub: 'Stewardship Hub' },

  { stpReviewPro: 'Stewardship: Review Pro' },
  { stpDueDVault: 'Stewardship: DueD Vault' },
  { stpFundConnect: 'Stewardship: Fund Connect' },
  { stpPRIMonitor: 'Stewardship: PRI Monitor' },

  // { stewardshipICBC: 'Stewardship: ICBC' },
  // { stewardshipSCB: 'Stewardship: SCB' },
  // { stewardshipCCBA: 'Stewardship: CCBA' },

  { stewardshipAdmin: 'Stewardship: Admin' },
  { stewardshipApprover: 'Stewardship: Approver' },
  { stewardshipEditor: 'Stewardship: Editor' },
  { stewardshipViewer: 'Stewardship: Viewer' },

  { crmAdminRole: 'CRM: Admin' },
  { crmApproverRole: 'CRM: Approver' },
  { crmEditorRole: 'CRM: Editor' },
  { crmViewerRole: 'CRM: Viewer' },
  { crmMasType: 'CRM: MAS' },
  { crmSfcType: 'CRM: SFC' },

  { crmScenario: 'CRM: Scenario' },
  { scenarioAdmin: 'Scenario: Admin' },
  { scenarioEditor: 'Scenario: Editor' },
  { scenarioViewer: 'Scenario: Viewer' },

  { premiumTcfd: 'TCFD' },
  { premiumSfdr: 'SFDR' },
  { sfdrPortfolio: 'SFDR: Portfolio' },
  { premiumUnSdg: 'UN SDG' },
  { premiumSasb: 'SASB' },
  { companyComparison: 'Company Comparison' },
  { fundComparison: 'Fund Comparison' },
  { portfolioComparison: 'Portfolio Comparison' },
  { productBas: ROLES_NAME.PRODUCT_BAS },
  { basFundhouse: 'BAS Fundhouse' },
  { basJuror: 'BAS Juror' },
  { basAdmin: 'BAS Admin' },
  { apiPortal: 'API Portal' },
  { productKingkey: ROLES_NAME.PRODUCT_KINGKEY },
  { productTWCS: ROLES_NAME.PRODUCT_TWCS },
  { productSME: ROLES_NAME.PRODUCT_SME },
  { productNXMap: ROLES_NAME.PRODUCT_NXMAP },
  { msFundScreener: 'MS: Fund Screener' },
  { msCompanyScreener: 'MS: Company Screener' },
  { msSfdrFund: 'MS: SFDR Fund' },
  { msSfdrPortfolio: 'MS: SFDR Portfolio' },
  { productHubs: ROLES_NAME.PRODUCT_HUBS },
  { quickSearch: 'Common: Quick Search' },
  { companySearch: 'Company Search' },
  { companyDetail: 'Company Detail' },
  { privateCompanyHub: 'Private Company Hub' },
];

//mapping { 'fundHub':'Fund Hub', ... }
export const namedMapping = namedMapper.reduce((r, v) => { return { ...r, ...v }; }, {});
//mapping { 'Fund Hub':'fundHub', ... }
export const namedMappingRev: { [key: string]: string; } = Object.keys(namedMapping)
  .reduce((r, v) => { return { ...r, ...{ [namedMapping[v]]: v } }; }, {});

//['fundHub','portfolioHub',...]
export const namedList = Object.keys(namedMapping);


export type roleType = {
  fundHub: boolean;
  portfolioHub: boolean;
  companyHub: boolean;
  climateHub: boolean;
  impactHub: boolean;
  kioskHomepage: boolean;
  kioskFundhub: boolean;
  kioskPortfoliohub: boolean;
  fundSearch: boolean;
  fundHubScreener: boolean;
  companyHubScreener: boolean;
  productKiosk: boolean;
  notifyNewMemberAuditor: boolean;
  notifyMemberExpiry?: boolean;
  notifyCoolingPeriod: boolean;
  portfolioHubPlus: boolean;

  stewardshipHub: boolean;

  stpReviewPro: boolean;
  stpDueDVault: boolean;
  stpFundConnect: boolean;
  stpPRIMonitor: boolean;

  // stewardshipICBC: boolean;
  // stewardshipSCB: boolean;
  // stewardshipCCBA: boolean;

  stewardshipAdmin: boolean;
  stewardshipApprover: boolean;
  stewardshipEditor: boolean;
  stewardshipViewer: boolean;

  crmAdminRole: boolean;
  crmApproverRole: boolean;
  crmEditorRole: boolean;
  crmViewerRole: boolean;
  crmMasType: boolean;
  crmSfcType: boolean;

  crmScenario: boolean;
  scenarioAdmin: boolean;
  scenarioEditor: boolean;
  scenarioViewer: boolean;

  premiumTcfd: boolean;
  premiumSfdr: boolean;
  sfdrPortfolio: boolean;
  premiumUnSdg: boolean;
  premiumSasb: boolean;
  companyComparison: boolean;
  fundComparison: boolean;
  portfolioComparison: boolean;

  productBas: boolean;
  basFundhouse: boolean;
  basJuror: boolean;
  basAdmin: boolean;
  apiPortal: boolean;

  productKingkey: boolean;
  productTWCS: boolean;
  productSME: boolean;
  productNXMap: boolean;
  msFundScreener: boolean;
  msCompanyScreener: boolean;
  msSfdrFund: boolean;
  msSfdrPortfolio: boolean;
  productHubs: boolean;

  quickSearch: boolean;

  companySearch: boolean;
  companyDetail: boolean;
  privateCompanyHub: boolean;
};

export const roleDefault = namedList.reduce((r: { [key: string]: boolean; }, v) => {
  r[v] = false;
  return r;
}, {}) as roleType;

export function rolePart(source: any) {
  return namedList.reduce((r: { [key: string]: boolean; }, v) => {
    r[v] = source?.[v];
    return r;
  }, {}) as roleType;
};

export function rolePartByHubRole(blueOnionHubRole: any) {
  return namedList.reduce((r: { [key: string]: any; }, v) => {
    r[v] = !!blueOnionHubRole?.[v]?.is_allow;
    return r;
  }, {}) as roleType;
}

export function start_end_date_object(blueOnionHubRole: any) {
  if (!blueOnionHubRole) return null;

  return namedList.reduce((r: { [key: string]: any; }, v) => {
    //fundHub_start_date: companyDetails?.blueOnionHubRole?.fundHub?.start_date ? moment(companyDetails?.blueOnionHubRole?.fundHub?.start_date) : null,
    //fundHub_end_date: companyDetails?.blueOnionHubRole?.fundHub?.end_date ? moment(companyDetails?.blueOnionHubRole?.fundHub?.end_date) : null,
    r[v + '_start_date'] = blueOnionHubRole?.[v]?.start_date ? moment(blueOnionHubRole?.[v]?.start_date) : null;
    r[v + '_end_date'] = blueOnionHubRole?.[v]?.end_date ? moment(blueOnionHubRole?.[v]?.end_date) : null;
    return r;
  }, {});
}
