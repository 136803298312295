import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { useQuery } from 'react-query';
import { Menu, Modal, ModalProps, Select, Spin, Switch, Tag, message } from 'antd';

//import { TextInput } from 'components/form-control';
import { SearchIcon, XCircleIcon } from 'components/icons';
import { CustomPagination } from 'components/pagination';
import Table from 'components/table';
import { GetListCompanyParamsType } from 'services/api-external-user.type';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { renderSorterOrder } from 'components/table/table.const';
import { NoDataTable } from 'components/no-data-table';
import { useHistory, useLocation } from 'react-router-dom';
import { useParseParams } from 'hooks/use-params';
import { pushParamHandle } from 'modules/user-management/external-user/company/company.const';
import { UserCompanyType } from 'modules/user-management/external-user/company/company-user-modal/company-user-modal.const';
//import { isNil } from 'utils/helper';
import moment from 'moment';
import axiosClient from 'utils/axios-client';
import { TextInput } from 'components/form-control';
import { isNil } from 'utils/helper';

import styles from './style.module.scss';

import SortingArrow from 'components/sorting-arrow';
import { CommonButton } from 'components/common-button';
import { RowActionMenu, RowActionMenuProps } from 'modules/user-management/external-user/company/row-action-menu';
import dayjs from 'dayjs';
import ImportByTemplateModal from 'components/modal/import-by-template-modal';
// import { arrayToCsv } from '../roles/tools';
import { AddObjectRoleModal, AddObjectRoleModalProps, IAddValues, IPageQuery, ValueItem } from '../add-object-role-modal';
import { externalUserApi } from 'services/api-external-user.services';
import { internalUserApi } from 'services/internal-user/internal-user.service';
import { arrayToCsv } from '../roles/tools';
import { ImportObjectRolesDto, regRoleName } from '../roles/roles.const';
import { WarningChar } from 'components/label-require/warning-char';
import { isDateInverted } from 'modules/user-management/external-user/company/change-plan-modal/warning-tool';
// import { CheckCircleOutlined, CheckCircleTwoTone } from '@ant-design/icons';
// import { regRoleName } from '../roles/roles.const';

const objectTypeOptions = [
  { label: 'Company', value: 'company' },
  { label: 'Member', value: 'members' },
  { label: 'Admin', value: 'user' },
];

const ACTION_LABEL = {
  EDIT: 'Edit',
  DELETE: 'Delete',
};

function ActionMenu({ row, className, action, ...props }: RowActionMenuProps) {
  const menu = (
    <Menu className={className}>
      <Menu.Item key='edit object-roles' onClick={() => { action(ACTION_LABEL.EDIT, row); }}>Edit</Menu.Item>
      <Menu.Divider />
      <Menu.Item key='delete object-roles' onClick={() => { action(ACTION_LABEL.DELETE, row); }}>Delete</Menu.Item>
    </Menu>
  );
  return <RowActionMenu {...{ row, className, action, ...props, menu }} />;
}

export interface ObjectRolesModalProps extends ModalProps {
  modalKey?: string;
  setModalVisible?: Dispatch<SetStateAction<boolean>> | undefined;
  title?: string;
  role_id?: number;
  role_name?: string;
  object_type?: string;
  object_id?: number;
  object_name?: string;
}

async function verifyImportData(rows: string[][], dtos: ImportObjectRolesDto[] | null = null) {
  // console.log(rows[0]);
  if (rows[0].slice(0, 5).join() !== 'table,role_name,is_allow,start_date,end_date') {
    return { result: false, message: 'header fail, ' + rows[0] };
  }
  if (rows.length < 2) {
    return { result: false, message: 'empty data' };
  }

  var cnt = 0;

  for (var i = 1; i < rows.length; i++) {
    //console.log(rows);
    var row = rows[i];

    if (row[0] !== 'object_roles') continue;

    if (!row[1].match(regRoleName)) {
      //console.log(row);
      return { result: false, line: i + 1, message: 'key name fail: ' + row };
    }

    if (dtos) {
      dtos.push({ table: row[0], role_name: row[1], is_allow: row[2], start_date: row[3], end_date: row[4] });
    }

    cnt++;
  }

  if (cnt < 1) {
    return { result: false, message: 'empty data for table \'roles\'' };
  }

  return { result: true };
}

function getDateStringFromImport(dt: string) {
  if (!dt) return undefined;

  if (dt.length <= 10) {
    let mnt = moment(dt, ['YYYY-MM-DD', 'DD/MM/YYYY', 'MM/DD/YYYY']);
    if (mnt.isValid()) return mnt.format('YYYY-MM-DD');
  }

  //if import from excel file, the date string will like 'Tue Dec 31 2024 08:00:00 GMT+0800 (Hong Kong Standard Time)'
  let mnt = moment(new Date(dt));
  if (mnt.isValid()) return mnt.format('YYYY-MM-DD');

  return undefined;
}

async function importData(rows: string[][], object_type: string, object_id: number) {
  var dtos: ImportObjectRolesDto[] = [];

  var r = await verifyImportData(rows, dtos);
  if (!r.result)
    return r;

  // console.log(dtos);
  try {
    var postData: any = [];
    dtos.forEach(row => {
      if (row.table === 'object_roles') postData.push({
        role_id: 0,
        role_name: row.role_name,
        is_allow: row.is_allow === '1',
        start_date: getDateStringFromImport(row.start_date || ''),
        end_date: getDateStringFromImport(row.end_date || ''),
      });
    });
    var url = '/object-roles/import/' + object_type + '/' + object_id;

    var res = await axiosClient.post(url, postData);
    if (res?.data.success) {
      return { result: true, message: `Import success, add ${res?.data.addCnt || 0}, update ${res?.data.updateCnt || 0}${res?.data.clearCnt ? (', clear ' + res?.data.clearCnt) : ''}, skip ${res?.data.skipCnt || 0}` };
    }
    else {
      return { result: false, message: JSON.stringify(res).slice(0, 255) };
    }
  }
  catch (ex: any) {
    //console.log(ex);
    return { result: false, message: (ex?.response?.data?.message || ('' + ex)) };
  }
}

export default function ObjectRoles(modalProps: ObjectRolesModalProps | undefined = undefined) {
  const history = useHistory();
  const location = useLocation();

  // console.log('modalProps', modalProps, new Date());

  const [pageParam, setPageParam] = useState<any>({});

  const urlParam = useParseParams();
  const [valueSearch, setValueSearch] = useState<string>(modalProps?.modalKey ? '' : urlParam.search);
  const [valueObjectType, setValueObjectType] = useState<string>(modalProps?.modalKey ? modalProps?.object_type : urlParam.objectType);

  useMemo(
    () => {
      setPageParam({
        role_id: modalProps?.role_id, object_id: modalProps?.object_id,
        objectType: valueObjectType, search: valueSearch,
      });
    },
    [modalProps, valueSearch, valueObjectType],
  );

  const { pageSize, page, search, sortOrder, sortBy, tabKey } = modalProps?.modalKey ? pageParam : urlParam;
  const sortable = urlParam.sortable;

  const [isAdding, setIsAdding] = useState(false);
  const [isOpenImportPopup, setOpenImportPopup] = useState<boolean>(false);

  var setParamHandle = useCallback(
    (data: GetListCompanyParamsType) => {
      pushParamHandle(
        location.pathname,
        {
          page: page ?? 1,
          pageSize: pageSize ?? 50,
          search: modalProps?.modalKey ? pageParam.search : search,
          sortOrder: sortOrder ?? 'desc',
          sortBy: sortBy ?? 'id',
          sortable: sortable || undefined,
          ...data,
        },
        history,
      );
    },
    [history, location.pathname, page, pageSize, search, sortBy, sortOrder, modalProps?.modalKey, pageParam.search, sortable],
  );

  const fetchListName1 = (param: IPageQuery, objectType?: string, objectId?: number) => {
    if (objectType && objectId) {
      return externalUserApi.getListRolesWithObject({
        page: param.page,
        pageSize: param.pageSize,
        search: param.search,
        sortBy: 'id',
        sortOrder: 'desc',
        objectType: objectType,
        objectId: objectId,
      });
    }
    else {
      return externalUserApi.getListRoles({
        page: param.page,
        pageSize: param.pageSize,
        search: param.search,
        sortBy: 'id',
        sortOrder: 'desc',
      });
    }
  };
  const fetchListCompany = (param: IPageQuery) => {
    return externalUserApi.getListCompany({
      page: param.page,
      pageSize: param.pageSize,
      search: param.search,
      sortBy: 'id',
      sortOrder: 'desc',
    });
  };
  const fetchListMembers = (param: IPageQuery) => {
    return externalUserApi.getListMembers({
      page: param.page,
      pageSize: param.pageSize,
      search: param.search,
      sortBy: 'id',
      sortOrder: 'desc',
    });
  };
  const fetchListUser = (param: IPageQuery) => {
    return internalUserApi.getListTable({
      page: param.page,
      pageSize: param.pageSize,
      search: param.search,
      sortBy: 'id',
      sortOrder: 'desc',
    });
  };

  const [addObjectRoleArgs, setAddObjectRoleArgs] = useState<AddObjectRoleModalProps>({
    modalKey: 'add-object-roles',
    setModalVisible: setIsAdding,
    title: '',
    values: undefined,
    onModalOK: undefined,
    fetchListName1: fetchListName1,
    fetchListName2: fetchListCompany,
  });

  const getObjectRolesList = (params: any) => {
    return axiosClient.get('/object-roles/with-object', {
      params: {
        ...params,
        sortOrder: params.sortOrder || 'desc',
        sortBy: params.sortBy || 'id',
        pageSize: params?.pageSize ?? 50,
        role_id: params?.role_id || undefined,
        objectType: params?.objectType,
        object_id: params?.object_id || undefined,
      },
    }).then((res) => {
      if (res.data) {
        return res.data;
      } else {
        return {};
      }
    });
  };

  //console.log([location.pathname, pageSize, page, search, sortOrder, sortBy, roleIds, tabKey]);

  const {
    data: listObjectRolesData,
    isLoading: isListObjectRolesLoading,
    refetch: refetchList,
  } = useQuery(
    [
      'getListObjectRoles',
      {
        pageSize,
        page,
        //status,
        search,
        sortBy,
        sortOrder,
        role_id: modalProps?.role_id,
        objectType: valueObjectType,
        object_id: modalProps?.object_id,
      },
    ], () => {
      // console.log('search', search);
      return getObjectRolesList({
        pageSize,
        page,
        status: undefined,  //urlStatus !== 'active,disabled' ? urlStatus : undefined,
        search: search?.trim(),
        sortBy,
        sortOrder,
        role_id: modalProps?.role_id,
        objectType: valueObjectType,
        object_id: modalProps?.object_id,
      });
    });

  // console.log('isListObjectRolesLoading', isListObjectRolesLoading);

  const setPageParamHandle = useCallback(
    (data: GetListCompanyParamsType) => {
      setPageParam({ ...pageParam, ...data });
      refetchList();
    },
    [pageParam, refetchList],
  );

  if (modalProps?.modalKey) setParamHandle = setPageParamHandle;

  const onChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserCompanyType>[] | SorterResult<any>,
  ) => {
    setParamHandle({
      sortBy: (sorter as SorterResult<UserCompanyType>).field as string,
      sortOrder: renderSorterOrder((sorter as SorterResult<UserCompanyType>).order as string),
      tabKey,
      objectType: valueObjectType,
    });
  };

  const renderTitle = (title: string, sortingFor?: string, tooltip?: string) => (
    <div className="renderTitle" key={title}>
      <div className="title">{title}</div>
      <SortingArrow order={sortOrder} orderBy={sortBy} sortingFor={sortingFor} />
    </div>
  );

  const renderTag = (objectType: string) => {
    if (objectType === 'company') return <Tag color='magenta' title='Company'>C</Tag>;
    else if (objectType === 'members') return <Tag color='green' title='Member'>M</Tag>;
    else if (objectType === 'user') return <Tag color='blue' title='Admin'>A</Tag>;
    else return <Tag>?</Tag>;
  };

  const nowDayStr = dayjs().format('YYYY-MM-DD');

  const renderDateValue = (value: any, row: any, is_start: boolean) => {
    let warn = [];
    if (value) {
      if (is_start && isDateInverted(row.start_date, nowDayStr)) warn.push('* Today is before the start date.');
      if (!is_start && isDateInverted(nowDayStr, row.end_date)) warn.push('* Today is after the end date.');

      if (row.object_type === 'members') {
        let member = listObjectRolesData?.memberData?.find((v: any) => v.id === row.object_id);
        let company = listObjectRolesData?.companyData?.find((v: any) => v.id === member?.company_id);

        if (company?.start_date && isDateInverted(company?.start_date, value)) warn.push('* Before the company service period.');
        if (company?.expired_date && isDateInverted(value, company?.expired_date)) warn.push('* After the company service period.');
        if (company?.plan_start_date && isDateInverted(company?.plan_start_date, value)) warn.push('* Before the company total control period.');
        if (company?.plan_end_date && isDateInverted(value, company?.plan_end_date)) warn.push('* After the company total control period.');

        if (member?.start_date && isDateInverted(member?.start_date, value)) warn.push('* Before the member service period.');
        if (member?.expired_date && isDateInverted(value, member?.expired_date)) warn.push('* After the member service period.');
        if (member?.plan_start_date && isDateInverted(member?.plan_start_date, value)) warn.push('* Before the member total control period.');
        if (member?.plan_end_date && isDateInverted(value, member?.plan_end_date)) warn.push('* After the member total control period.');

        let companyScope = company?.companyRole?.find((v: any) => v.role_id === row.role_id);
        if (companyScope?.start_date && isDateInverted(companyScope?.plan_start_date, value)) warn.push('* Before the company scope period.');
        if (companyScope?.end_date && isDateInverted(value, companyScope?.plan_end_date)) warn.push('* After the company scope period.');
      }
      else if (row.object_type === 'company') {
        let company = listObjectRolesData?.companyData?.find((v: any) => v.id === row.object_id);
        if (company?.start_date && isDateInverted(company?.start_date, value)) warn.push('* Before the company service period.');
        if (company?.expired_date && isDateInverted(value, company?.expired_date)) warn.push('* After the company service period.');
        if (company?.plan_start_date && isDateInverted(company?.plan_start_date, value)) warn.push('* Before the company total control period.');
        if (company?.plan_end_date && isDateInverted(value, company?.plan_end_date)) warn.push('* After the company total control period.');
      }
      else if (row.object_type === 'user') {
        let user = listObjectRolesData?.userData?.find((v: any) => v.id === row.object_id);
        if (user?.expired_date && isDateInverted(value, user?.expired_date)) warn.push('* After the admin service period.');
      }
    }

    let charString = <span style={{ color: (warn.length > 0) ? '#FC9536' : 'black' }}>{value}</span>;

    if (warn.length > 0) {
      // warn.unshift('The setting will not take effect.');
      // return <span style={{ color: '#FF424E', fontWeight: 'bold', cursor: 'default' }} title={warn.join('\n')}>{is_allow ? '✔' : ''}</span>;
      return <WarningChar
        warning={warn.join('\n')}
        charString={charString}
        message='The data will not take effect'
      />;
    }

    return charString;
  };

  const columns: ColumnsType<UserCompanyType> = [
    {
      align: 'left',
      title: renderTitle('ID', 'id'),
      dataIndex: 'id',
      key: 'id',
      width: 40,
      sorter: true,
      //ellipsis: true,
    },
    {
      align: 'left',
      title: renderTitle('Role', 'role.name'),
      dataIndex: 'role.name',
      key: 'role.name',
      width: 80,
      // ellipsis: true,
      sorter: true,
      render: (_, row: any) => {
        return row.role.name;
      },
    },
    {
      align: 'left',
      title: 'Name',
      dataIndex: 'object_name',
      key: 'object_name',
      width: 80,
      ellipsis: true,
      sorter: sortable,
      render: (_, row: any) => {
        return <span title={row.object_name}>{renderTag(row.object_type)}{row.object_name}</span>;
      },
    },
    {
      align: 'left',
      title: 'Information',
      dataIndex: 'object_info',
      key: 'object_info',
      width: 80,
      ellipsis: true,
      sorter: sortable,
    },
    {
      align: 'center',
      title: 'Allow',
      dataIndex: 'is_allow',
      key: 'is_allow',
      width: 30,
      sorter: sortable,
      render: (is_allow, row: any) => {
        let warn = [];
        if (is_allow) {
          if (row.start_date && isDateInverted(row.start_date, nowDayStr)) warn.push('* Today is before the start date.');
          if (row.end_date && isDateInverted(nowDayStr, row.end_date)) warn.push('* Today is after the end date.');

          if (row.object_type === 'members') {
            let member = listObjectRolesData?.memberData?.find((v: any) => v.id === row.object_id);
            let company = listObjectRolesData?.companyData?.find((v: any) => v.id === member?.company_id);
            if (!member?.is_isolate_role) {
              warn.push('* The member is not with isolated role flag');
            }

            if (company?.start_date && isDateInverted(company?.start_date, nowDayStr)) warn.push('* Today is before the company service period.');
            if (company?.expired_date && isDateInverted(nowDayStr, company?.expired_date)) warn.push('* Today is after the company service period.');
            if (company?.plan_start_date && isDateInverted(company?.plan_start_date, nowDayStr)) warn.push('* Today is before the company total control period.');
            if (company?.plan_end_date && isDateInverted(nowDayStr, company?.plan_end_date)) warn.push('* Today is after the company total control period.');

            if (member?.start_date && isDateInverted(member?.start_date, nowDayStr)) warn.push('* Today is before the member service period.');
            if (member?.expired_date && isDateInverted(nowDayStr, member?.expired_date)) warn.push('* Today is after the member service period.');
            if (member?.plan_start_date && isDateInverted(member?.plan_start_date, nowDayStr)) warn.push('* Today is before the member total control period.');
            if (member?.plan_end_date && isDateInverted(nowDayStr, member?.plan_end_date)) warn.push('* Today is after the member total control period.');

            let companyScope = company?.companyRole?.find((v: any) => v.role_id === row.role_id);
            if (!companyScope?.is_allow) warn.push('* Not allowed by company scope.');
            if (companyScope?.start_date && isDateInverted(companyScope?.plan_start_date, nowDayStr)) warn.push('* Today is before the company scope period.');
            if (companyScope?.end_date && isDateInverted(nowDayStr, companyScope?.plan_end_date)) warn.push('* Today is after the company scope period.');
          }
          else if (row.object_type === 'company') {
            let company = listObjectRolesData?.companyData?.find((v: any) => v.id === row.object_id);
            if (company?.start_date && isDateInverted(company?.start_date, nowDayStr)) warn.push('* Today is before the company service period.');
            if (company?.expired_date && isDateInverted(nowDayStr, company?.expired_date)) warn.push('* Today is after the company service period.');
            if (company?.plan_start_date && isDateInverted(company?.plan_start_date, nowDayStr)) warn.push('* Today is before the company total control period.');
            if (company?.plan_end_date && isDateInverted(nowDayStr, company?.plan_end_date)) warn.push('* Today is after the company total control period.');
          }
          else if (row.object_type === 'user') {
            let user = listObjectRolesData?.userData?.find((v: any) => v.id === row.object_id);
            if (user?.expired_date && isDateInverted(nowDayStr, user?.expired_date)) warn.push('* Today is after the admin service period.');
          }
        }

        let charString = <span style={{ color: (warn.length > 0) ? '#FC9536' : 'green', fontWeight: 'bold', cursor: 'default' }}>{is_allow ? '✔' : ''}</span>;

        if (warn.length > 0) {
          // warn.unshift('The setting will not take effect.');
          // return <span style={{ color: '#FF424E', fontWeight: 'bold', cursor: 'default' }} title={warn.join('\n')}>{is_allow ? '✔' : ''}</span>;
          return <WarningChar
            warning={warn.join('\n')}
            charString={charString}
            message='The data will not take effect'
          />;
        }

        return charString;
      },
    },
    {
      align: 'center',
      title: 'Start',
      dataIndex: 'start_date',
      key: 'start_date',
      width: 60,
      sorter: sortable,
      render: (value: any, row: any) => { return renderDateValue(value, row, true); },
    },
    {
      align: 'center',
      title: 'End',
      dataIndex: 'end_date',
      key: 'end_date',
      width: 60,
      sorter: sortable,
      render: (value: any, row: any) => { return renderDateValue(value, row, false); },
    },
    {
      align: 'left',
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 70,
      sorter: sortable,
      ellipsis: true,
      render: (_, data: any) => {
        return (
          <div>{isNil(data.created_at) ? '-' : moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')}</div>
        );
      },
    },
    {
      align: 'left',
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 70,
      sorter: sortable,
      ellipsis: true,
      render: (_, data: any) => {
        return (
          <div>{isNil(data.updated_at) ? '-' : moment(data.updated_at).format('YYYY-MM-DD HH:mm:ss')}</div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'objectRolesAction',
      key: 'objectRolesAction',
      width: 50,
      align: 'center',
      fixed: 'right',

      render: (_, data: UserCompanyType) => {
        return (
          <ActionMenu row={data} className={'actionMenu'} action={onRolePemissionAction} />
        );
      },

    },
  ];

  const ClearFilters = () => {
    setValueSearch('');
    setValueObjectType('');
    setParamHandle({ page: 1, pageSize: 50, search: undefined, tabKey, objectType: modalProps?.object_type || undefined });
  };

  const onChangePage = (page: number, pageSize: number) => {
    setParamHandle({ page, pageSize, tabKey, objectType: valueObjectType });
  };

  const totalResult = useMemo(() => {
    return listObjectRolesData?.total ?? 0;
  }, [listObjectRolesData?.total]);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValueSearch(e.target.value);
    setTimeout(() => {
      setParamHandle({ tabKey, search: e.target.value, objectType: valueObjectType, page: 1 });
    }, 700);
  };

  const onAddObjectRolesOk = async (values: IAddValues, roleList?: ValueItem[], objectList?: ValueItem[]) => {

    // multi-select roles
    if (roleList?.length) {
      // console.log(values, roleList);
      const submitList = roleList.map((v) => ({
        role_id: +v.value,
        // role_name: v.label,
        start_date: values.start_date || null,
        end_date: values.end_date || null,
        is_allow: values.is_allow || false,
      }));

      try {
        let res = await axiosClient.post(`/object-roles/import/${values.object_type}/${values.object_id}`, submitList);
        if (res?.data.success) {
          message.success(`Success, add ${res?.data.addCnt || 0}, update ${res?.data.updateCnt || 0}${res?.data.clearCnt ? (', clear ' + res?.data.clearCnt) : ''}, skip ${res?.data.skipCnt || 0}`);
          refetchList();
        }
        else {
          message.error(JSON.stringify(res).slice(0, 255));
        }
      }
      catch (ex: any) {
        //console.log(ex);
        message.error(ex?.response?.data?.message || ('' + ex));
      }

      return;
    }

    //multi-select object
    if (objectList?.length) {
      const submitList = objectList.map((v) => ({
        object_id: +v.value,
        start_date: values.start_date || null,
        end_date: values.end_date || null,
        is_allow: values.is_allow || false,
      }));

      try {
        let res = await axiosClient.post(`/object-roles/import-objects/${values.object_type}/${values.role_id}`, submitList);
        if (res?.data.success) {
          message.success(`Success, add ${res?.data.addCnt || 0}, update ${res?.data.updateCnt || 0}${res?.data.clearCnt ? (', clear ' + res?.data.clearCnt) : ''}, skip ${res?.data.skipCnt || 0}`);
          refetchList();
        }
        else {
          message.error(JSON.stringify(res).slice(0, 255));
        }
      }
      catch (ex: any) {
        //console.log(ex);
        message.error(ex?.response?.data?.message || ('' + ex));
      }

      return;
    }

    // console.log(values);
    axiosClient.post('/object-roles', {
      role_id: values.role_id,
      object_id: values.object_id,
      object_type: values.object_type,
      is_allow: values.is_allow || null,
      start_date: values.start_date || null,
      end_date: values.end_date || null,
    }).then((res) => {
      // console.log(res);
      message.success('Success to create a object-roles');
      refetchList();
    }).catch((ex) => {
      // console.log(ex);
      message.error('Error: ' + (ex?.response?.data?.message || ex));
    });
  };

  const modalObjectTypeName = objectTypeOptions.find((v) => v.value === modalProps?.object_type)?.label;

  const onAddObjectRoles = () => {
    if (!valueObjectType) return;

    var objectTypeName = objectTypeOptions.find((v) => v.value === valueObjectType)?.label;

    var fetchListName2;
    if (valueObjectType === 'company') fetchListName2 = fetchListCompany;
    else if (valueObjectType === 'members') fetchListName2 = fetchListMembers;
    else if (valueObjectType === 'user') fetchListName2 = fetchListUser;
    if (!fetchListName2) return;

    setAddObjectRoleArgs({
      ...addObjectRoleArgs,
      title: 'Add ' + objectTypeName + ' Role',
      values: {
        role_id: modalProps?.role_id || 0,
        role_name: modalProps?.role_name || '',
        object_id: modalProps?.object_id || 0,
        object_name: modalProps?.object_name || '',
        is_allow: true,
      },
      objectType: valueObjectType,
      objectTypeName: objectTypeName,
      onModalOK: onAddObjectRolesOk,
      fetchListName2,
      nameField2: (valueObjectType === 'members') ? ['username', 'email'] : 'name',
    });
    setIsAdding(true);
    // console.log('open add dlg');
  };

  const onEditObjectRolesOk = (values: IAddValues) => {
    // console.log(values);
    axiosClient.put('/object-roles/' + values.rowId, {
      role_id: values.role_id,
      object_id: values.object_id,
      object_type: values.object_type,
      is_allow: values.is_allow || null,
      start_date: values.start_date || null,
      end_date: values.end_date || null,
    }).then((res) => {
      // console.log(res);
      message.success('Success to update an object-roles');
      refetchList();
    }).catch((ex) => {
      // console.log(ex);
      message.error('Error: ' + (ex?.response?.data?.message || ex));
    });
  };

  const handleEditObjectRoles = (row: any) => {
    var rowObjectType = row.object_type;
    var rowObjectTypeName = objectTypeOptions.find((v) => v.value === rowObjectType)?.label;

    var fetchListName2;
    if (rowObjectType === 'company') fetchListName2 = fetchListCompany;
    else if (rowObjectType === 'members') fetchListName2 = fetchListMembers;
    else if (rowObjectType === 'user') fetchListName2 = fetchListUser;
    if (!fetchListName2) return;

    setAddObjectRoleArgs({
      ...addObjectRoleArgs,
      title: 'Edit Role for ' + rowObjectTypeName + ': ' + row.object_name,
      values: {
        rowId: row.id,
        role_id: row.role_id,
        object_id: row.object_id,
        is_allow: row.is_allow,
        start_date: row.start_date,
        end_date: row.end_date,
        role_name: row.role.name,
        object_name: row.object_name,
      },
      objectType: rowObjectType,
      objectTypeName: rowObjectTypeName,
      onModalOK: onEditObjectRolesOk,
      fetchListName2,
      nameField2: (rowObjectType === 'members') ? ['username', 'email'] : 'name',
    });
    setIsAdding(true);
    // console.log('open add dlg');
  };

  const handleDeleteObjectRoles = (row: any) => {
    var rowObjectType = row.object_type;
    var rowObjectTypeName = objectTypeOptions.find((v) => v.value === rowObjectType)?.label;

    var valueCheck = row.role.name + ' + ' + rowObjectTypeName + ':' + row.object_name;
    // var value: any = '';
    // var modal = 
    Modal.confirm({
      title: 'Confirm Deleting',
      content: <p>Please confirm the deleting of the role of<br /><span style={{ color: 'green' }}>{valueCheck}</span>.
      </p>,
      width: 600,
      // okButtonProps: { disabled: true },
      closable: true,
      maskClosable: true,
      onOk: () => {
        // console.log(value);
        axiosClient.delete('/object-roles/' + row.id).then((res) => {
          // console.log(res);
          message.success('Success to delete an object-roles');
          refetchList();
        }).catch((ex) => {
          // console.log(ex);
          message.error('Error: ' + (ex?.response?.data?.message || ex));
        });
      },
    });

  };

  async function onRolePemissionAction(label: string, row: any) {
    if (label === ACTION_LABEL.EDIT) handleEditObjectRoles(row);
    else if (label === ACTION_LABEL.DELETE) handleDeleteObjectRoles(row);
  }

  const onExportRoles = () => {
    const num = listObjectRolesData.data.length || 0;
    if (!(num > 0)) {
      message.info('no role to export in current page.');
      return;
    }

    var roleValues: any = [];
    var onlyAllowed = true;
    var includeDate = true;

    var updateValues = () => {
      const roleMap: any = {};
      listObjectRolesData.data.forEach((row: any) => {
        if (!(row.role.name in roleMap)) {
          if (row.is_allow || !onlyAllowed) roleMap[row.role.name] = {
            role_name: row.role.name,
            is_allow: row.is_allow ? 1 : '',
            start_date: row.start_date,
            end_date: row.end_date,
          };
        }
      });
      roleValues = Object.values(roleMap);
    };
    updateValues();

    var updateContent = () => {
      return (<p>
        Please confirm to export the <b style={{ color: 'green' }}>{roleValues.length}</b> role{(roleValues.length > 1) ? 's ' : ' '}
        {onlyAllowed ? 'that with the allow-flag' : ''} from {num} row{(num > 1) ? 's' : ''} in current page?  (Note: for the duplicated roles, only export the first one.)<br />
        <br />
        <Switch defaultChecked={true} onChange={(checked) => {
          onlyAllowed = checked;
          updateValues();
          modal.update({ content: updateContent(), okButtonProps: { disabled: !roleValues.length } });
        }} /> &nbsp; Only Allowed <br />
        <br />
        <Switch defaultChecked={true} onChange={(checked) => { includeDate = checked; }} /> &nbsp; Include Start/End Date
      </p>);
    };

    const modal = Modal.confirm({
      title: 'Export Confirmation',
      content: updateContent(),
      closable: true,
      maskClosable: true,
      width: 600,
      okButtonProps: { disabled: !roleValues.length },
      onOk: async () => {
        var exData = [
          ['table', 'role_name', 'is_allow', 'start_date', 'end_date'],
        ];
        roleValues.forEach((row: any) => {
          exData.push(['object_roles', row.role_name, row.is_allow, ...(includeDate ? [row.start_date, row.end_date] : [])]);
        });

        //sort
        exData = [
          exData[0],
          ...exData.slice(1).sort((a, b) => {
            return a[1].localeCompare(b[1], undefined, { sensitivity: 'base' });
          }),
        ];

        const csvContent =
          'data:text/csv;charset=utf-8,' + arrayToCsv(exData);
        const encodedUri = encodeURI(csvContent);

        var ojectTypeName = objectTypeOptions.find((v) => v.value === valueObjectType)?.label?.toLowerCase();

        const link = document.createElement('a');
        link.href = encodedUri;
        link.download = (ojectTypeName || 'object') + '-roles-' + dayjs().format('YYYYMMDD-HHmmss') + '.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    });
  };

  return (
    <>
      <div key={'ObjectRolesPanel' + tabKey} className='objectRolesPanel'>
        <p className="filter-hub-text filter-hub-top-text">Filter Obejct-Roles</p>
        <div className="filter-hub-flex">
          <div className="filter-hub-options">
            <Select
              // showSearch
              optionFilterProp='label'
              placeholder='Select Type'
              value={valueObjectType}
              onChange={(v: string) => {
                setParamHandle({ page: 1, objectType: v, tabKey });
                setValueObjectType(v);
              }}
              options={objectTypeOptions}
              maxTagCount="responsive"
              virtual={false}
              className='input'
              size='large'
              // mode="multiple"
              // mode='tags'
              showArrow
              allowClear

              disabled={modalProps?.object_id ? true : false}

              style={{ marginRight: '0.5em', width: '10em' }}
            />

            <TextInput
              placeholder="Search names"
              className="input"
              inputProps={{
                onChange: onChangeInput,
                value: valueSearch,
                prefix: <SearchIcon width={20} height={20} />,
                maxLength: 255,
              }}
            // disabled={!!modalProps?.modalKey}
            />
          </div>
          <div className="filter-hub-btns" style={{ display: 'flex' }}>
            <div className="clear-filters" onClick={ClearFilters}>
              <span>Clear all filters</span>
              <XCircleIcon width={24} height={24} />
            </div>
            <CommonButton variant="link"
              key="add-object-roles"
              onClick={onAddObjectRoles}
              disabled={!valueObjectType}
              style={{ marginLeft: '2em' }}
              title={valueObjectType ? '' : 'Select a type to enable adding.'}
            >
              Add
            </CommonButton>
            <CommonButton variant="link"
              key="export-roles-from-object-roles"
              onClick={onExportRoles}
              disabled={false}
              style={{ marginLeft: '0.5em' }}
            >
              Export
            </CommonButton>
            {modalProps?.object_id && (<CommonButton variant="link"
              key="import-role-for-object-roles"
              onClick={() => { setOpenImportPopup(true); }}
              disabled={false}
              style={{ marginLeft: '0.5em' }}
            >
              Import
            </CommonButton>)}
          </div>
        </div>
        {isListObjectRolesLoading ? (
          <Spin className="spin" />
        ) : (
          <>
            {!listObjectRolesData?.data || listObjectRolesData?.data.length === 0 ? (
              <NoDataTable />
            ) : (
              <>
                <Table
                  rowKey={'id'}
                  columns={columns}
                  dataSource={listObjectRolesData?.data || []}
                  pagination={false}
                  onChange={onChangeTable}
                  loading={isListObjectRolesLoading}
                />

                <div className="pagination">
                  <div className="total-results-text">
                    Total results: <p className="total-results-number">{totalResult}</p>
                  </div>
                  <CustomPagination
                    pageSize={listObjectRolesData?.pageSize ?? 50}
                    current={listObjectRolesData?.page ?? 1}
                    total={totalResult}
                    onChange={onChangePage}
                    showSizeChanger={true}
                  />
                </div>
              </>
            )}
          </>
        )}

      </div>

      {isAdding && (
        <AddObjectRoleModal
          open={true}
          // onCancel={() => { setIsItemEditing(false); }}
          // setModalVisible={setIsAdding}
          {...addObjectRoleArgs}
        />
      )}

      {isOpenImportPopup && (
        <ImportByTemplateModal
          title={'Import Roles for ' + modalObjectTypeName + ':' + modalProps?.object_name}
          verifyData={async (rows: string[][]) => await verifyImportData(rows, null)}
          closePopup={() => { setOpenImportPopup(false); }}
          importData={async (rows: string[][]) => {
            var ret = await importData(rows, modalProps?.object_type || '', modalProps?.object_id || 0);
            await refetchList();
            return ret;
          }}
          action='upload-import-object-roles'
          templateName='setting-import-object-roles-template'
          xlsxTemplateUrl='/template/setting-import-object-roles-template.xlsx'
          csvTemplateUrl='/template/setting-import-object-roles-template.csv'
        />
      )}

    </>
  );
}

export function ObjectRolesModal(props: ObjectRolesModalProps) {
  return (
    <div >
      <Modal
        {...props}
        // onCancel={onCancel}
        centered
        title={props?.title || 'Object-Roles'}
        forceRender
        className={styles.objectRolesModal}
        // style={{ width: '90%' }}
        width={'95%'}
        closable={true}
        maskClosable={true}
        footer={''}
        onCancel={() => { props.setModalVisible?.(false); }}
      >
        <ObjectRoles
          {...props}
        />
      </Modal>
    </div>
  );
}
