import { Button, Form, FormProps, Input, message } from 'antd';
import { CompanyPropertyModalInputProp } from '..';
import { useEffect, useState } from 'react';
import { externalUserApi } from '../../../../../services/api-external-user.services';
import { ChangeCompanyPropertyBody, FetchCompanyProperty } from '../../../../../services/api-company-user.type';
import TextArea from 'antd/lib/input/TextArea';
import { isEmpty } from 'lodash';
import { ModalBase } from 'components/modal';
import styles from './company-property-modal.module.scss';
import { CommonButton } from 'components/common-button';

type FieldType = {
  firmName: string;
  masID?: string;
  sfcID?: string;
  firmDescription: string;
};

const CompanyPropertyModal = (props: CompanyPropertyModalInputProp) => {
  const [modalOpen, setModalOpen] = useState(true);
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [companyData, setCompanyData] = useState<FieldType>({} as FieldType);

  const [form] = Form.useForm();
  const firmName = Form.useWatch('firmName', form) || '';
  const masID = Form.useWatch('masID', form) || '';
  const sfcID = Form.useWatch('sfcID', form) || '';
  const firmDescription = Form.useWatch('firmDescription', form) || '';

  useEffect(() => {
    const fetchCompanyPropertyData = async () => {
      const companyID = props.companyData?.id;
      let response: FetchCompanyProperty = {} as FetchCompanyProperty;
      if (companyID) {
        response = await externalUserApi.getCompanyProperty(+companyID);
        form.setFieldsValue({
          firmName: response?.data?.firm_name || '',
          masID: response?.data?.mas_id || '',
          sfcID: response?.data?.sfc_id || '',
          firmDescription: response?.data?.description || '',
        });
      }

      setCompanyData({
        firmName: response?.data?.firm_name || '',
        masID: response?.data?.mas_id || '',
        sfcID: response?.data?.sfc_id || '',
        firmDescription: response?.data?.description || '',
      });
    };
    fetchCompanyPropertyData();
  }, [props.companyData?.id, form]);

  useEffect(() => {
    if (Object.keys(companyData).length !== 0) {
      const companyFormDataIsChanged =
        companyData.firmName !== firmName ||
        companyData.masID !== masID ||
        companyData.sfcID !== sfcID ||
        companyData.firmDescription !== firmDescription;

      const requiredCompanyFormDataFieldIsValid = !isEmpty(firmName); // && !isEmpty(firmDescription);
      if (companyFormDataIsChanged && requiredCompanyFormDataFieldIsValid) {
        setIsSubmitDisable(false);
      } else {
        setIsSubmitDisable(true);
      }
    }
  }, [
    firmName,
    masID,
    sfcID,
    firmDescription,
    companyData,
    companyData.firmName,
    companyData.masID,
    companyData.sfcID,
    companyData.firmDescription,
  ]);

  const handleCancel = () => {
    props.setModalVisible?.(false);
  };

  const onFinish: FormProps<FieldType>['onFinish'] = async (values: FieldType) => {
    const companyID = props.companyData?.id || -1;
    const payload: ChangeCompanyPropertyBody = {
      firm_name: values.firmName,
      mas_id: values.masID,
      sfc_id: values.sfcID,
      description: values.firmDescription,
    };
    let resp = await externalUserApi.changeCompanyProperty(+companyID, payload);

    setModalOpen(false);
    props.setModalVisible?.(false);

    if (resp?.data?.success) {
      message.success('update success');
    }
    else {
      message.error('update fail');
    }
  };

  let fullCompanyTitle = props.companyData?.name || '';
  let shortCompanyTitle = (fullCompanyTitle.length > 20) ? (fullCompanyTitle.slice(0, 20) + '...') : fullCompanyTitle;

  return (
    <div>
      <ModalBase
        open={modalOpen}
        title={<>Company Property - <span title={fullCompanyTitle}>{shortCompanyTitle}</span></>}
        onCancel={handleCancel}
        className={styles.companyPropertyModal}
        width={500}
      >
        <Form
          form={form}
          name="company-property-form"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 500, width: '100%' }}
          initialValues={{
            remember: true,
            firmName: companyData.firmName,
            masID: companyData.masID,
            sfcID: companyData.sfcID,
            firmDescription: companyData.firmDescription,
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item<FieldType>
            label={<>
              Firm Name
              <Button
                type="link"
                size='small'
                onClick={() => {
                  form.setFieldsValue({
                    firmName: props.companyData?.name,
                  });
                }}
                style={{ marginLeft: '200px' }}
                title='Fill with company name'
              >
                company name
              </Button>
            </>}
            name="firmName"
            rules={[{ required: true, message: 'Firm name cannot be empty.' }]}
          >
            <Input showCount maxLength={255} placeholder="Firm Name" />
          </Form.Item>
          <Form.Item<FieldType> label="MAS ID" name="masID" rules={[]}>
            <Input showCount maxLength={255} placeholder="MAS ID" />
          </Form.Item>
          <Form.Item<FieldType> label="SFC ID" name="sfcID" rules={[]}>
            <Input showCount maxLength={255} placeholder="SFC ID" />
          </Form.Item>
          <Form.Item<FieldType>
            label="Firm Description"
            name="firmDescription"
            // rules={[{ required: true, message: 'Firm description cannot be empty.' }]}
          >
            <TextArea showCount maxLength={5000} placeholder="Firm Description" rows={4} />
          </Form.Item>

          <div className={styles.alignButton}>
            <CommonButton onClick={handleCancel} variant="default" idKey='company-property-cancel'>
              Cancel
            </CommonButton>
            <Form.Item shouldUpdate className={styles.editItemButton}>
              {() => {
                return (
                  <CommonButton
                    htmlType="submit"
                    block
                    size="large"
                    variant="primary"
                    disabled={isSubmitDisable}
                    idKey='company-property-save'
                  >
                    Save
                  </CommonButton>
                );
              }
              }
            </Form.Item>
          </div>
        </Form>
      </ModalBase>
    </div>
  );
};

export default CompanyPropertyModal;
